import { useCallback, useEffect } from "react";
import * as Tone from "tone";

export const useSound = () => {
  useEffect(() => {
    (async () => {
      await Tone.start();
      console.log("Tone started");
    })();
  }, []);

  const playBassBeep = useCallback((duration: number, fps: number) => {
    const comp = new Tone.Compressor(-20, 8);

    const sineSynths = [
      new Tone.Synth({
        oscillator: { type: "sine" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.7, release: 0.1 },
        volume: -8,
      }),
      new Tone.Synth({
        oscillator: { type: "sine" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.7, release: 0.1 },
        volume: -16,
      }),
      new Tone.Synth({
        oscillator: { type: "sine" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.7, release: 0.1 },
        volume: -24,
      }),
    ];

    const triangleSynths = [
      new Tone.Synth({
        oscillator: { type: "triangle" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.6, release: 0.1 },
        volume: -10,
      }),
      new Tone.Synth({
        oscillator: { type: "triangle" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.6, release: 0.1 },
        volume: -20,
      }),
      new Tone.Synth({
        oscillator: { type: "triangle" },
        envelope: { attack: 0.01, decay: 0.1, sustain: 0.6, release: 0.1 },
        volume: -30,
      }),
    ];

    sineSynths.forEach((synth) => synth.connect(comp).toDestination());
    triangleSynths.forEach((synth) => synth.connect(comp).toDestination());

    const frequency = duration <= 15 ? 65 : 40;
    sineSynths.forEach((synth, i) =>
      synth.triggerAttackRelease(frequency * (i + 1), duration / fps),
    );
    triangleSynths.forEach((synth, i) =>
      synth.triggerAttackRelease(frequency * (i + 1), duration / fps),
    );

    setTimeout(() => {
      sineSynths.forEach((synth) => synth.dispose());
      triangleSynths.forEach((synth) => synth.dispose());
      comp.dispose();
    }, 2000);
  }, []);

  return { playBassBeep };
};
