import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMeta, clearMeta, MetaState } from "../store/meta";

export const NotFound: FC = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    const meta: MetaState = {
      path: location.pathname,
      type: "",
      pageType: "notFound",
      title: "404 Not Found",
      keywords: "",
      description: "",
    };

    dispatch(setMeta(meta));
    return () => {
      dispatch(clearMeta());
    };
  }, [props, dispatch, location]);

  return (
    <article className="not-found">
      <h1>404 Not Found </h1>
      <p>The requested resource could not be found on this server.</p>
    </article>
  );
};
