import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

// Update body classes on page change
export const BodyClasses: FC = () => {
  const { body } = document;
  const isWork = useSelector((state: RootState) => state.works.isWork);

  useEffect(() => {
    setTimeout(() => {
      body.classList.add("loaded");
    }, 400);

    // Change background-color on the "work" pages
    setTimeout(() => {
      if (isWork) {
        body.classList.add("work");
      } else {
        body.classList.remove("work");
      }
    }, 150);
  }, [body.classList, isWork]);

  return null;
};
