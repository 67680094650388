import React, { FC, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import WorksData from "../../vo/WorksData";
import { CustomAnchor } from "../CustomAnchor";
import { CustomButton } from "../CustomButton";

import Styles from "../../scss/components/WorksSubmenu.module.scss";

// Submenu for the Works
export const WorksSubmenu: FC = () => {
  const { body } = document;

  // delay evaluation
  const pageType = useSelector((state: RootState) => state.meta.pageType);
  const [isWork, setIsWork] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (pageType === "work") {
        setIsWork(true);
      } else {
        setIsWork(false);
      }
    }, 150);
  }, [isWork, pageType]);

  const timerIdRef = useRef<number>();
  const [isShowed, setIsShowed] = useState(false);
  const [isCloseTouch, setIsCloseTouch] = useState(false);
  const [submenuPosition, setSubmenuPosition] = useState({
    left: -10000,
    top: 0,
    maxHeight: 0,
    overflowY: "hidden",
  } as React.CSSProperties);

  const button = document.querySelector("#header_works");
  const submenu = document.querySelector("#header_works_submenu");

  // Submenu Handlers
  const showSubmenu = () => {
    window.clearTimeout(timerIdRef.current);
    setIsShowed(true);

    // Set submenu position
    if (button && submenu) {
      setSubmenuPosition({
        left: isMobile ? 0 : -15,
        top: isMobile ? 0 : 26,
        overflowY: "hidden",
      });
    }

    if (isMobile) {
      body.style.overflow = "hidden";
    }
  };

  const hideSubmenu = () => {
    window.clearTimeout(timerIdRef.current);
    setIsShowed(false);

    setSubmenuPosition({
      left: submenuPosition.left,
      top: submenuPosition.top,
      overflowY: "hidden",
    });

    if (isMobile) {
      body.style.overflow = "auto";
    }
  };

  const onShownSubmenu = () => {
    setSubmenuPosition({
      left: submenuPosition.left,
      top: submenuPosition.top,
      overflowY: "auto",
    });
  };

  const onHiddenSubmenu = () => {
    setSubmenuPosition({
      left: -10000,
      top: 0,
      overflowY: "hidden",
    });

    submenu?.parentElement?.scrollTo(0, 0);
  };

  // Mouse, touch
  const onMouseOverHandler = () => {
    if (isMobile) return;
    showSubmenu();
  };

  const onMouseOutHandler = () => {
    if (isMobile) return;

    timerIdRef.current = window.setTimeout(() => {
      hideSubmenu();
    }, 500);
  };

  const onTouchStartHandler = () => {
    // if (!isMobile) return
  };

  const onTouchEndHandler = () => {
    if (!isMobile) return;
    window.clearTimeout(timerIdRef.current);
    showSubmenu();
  };

  return (
    <>
      <div
        id="header_works_container"
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        onFocus={onMouseOverHandler}
        onBlur={onMouseOutHandler}
        onTouchStart={onTouchStartHandler}
        onTouchEnd={onTouchEndHandler}
      >
        <CustomButton id="header_works" title="Works" click={() => false} />
      </div>
      <div
        className={`${Styles.submenu_container} ${isShowed ? Styles.show : ""} ${isWork ? Styles.work : ""}`}
        style={submenuPosition}
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        onFocus={onMouseOverHandler}
        onBlur={onMouseOutHandler}
        onTransitionEnd={isShowed ? onShownSubmenu : onHiddenSubmenu}
      >
        <div id="header_works_submenu" className={Styles.submenu}>
          <SubmenuList yearlyLinks={WorksData} hideSubmenu={hideSubmenu} />
        </div>
        <button
          type="button"
          onTouchStart={() => setIsCloseTouch(true)}
          onTouchEnd={() => setIsCloseTouch(false)}
          onClick={hideSubmenu}
          className={`${Styles.close} ${isMobile && isShowed ? Styles.show : ""} ${isCloseTouch ? Styles.on : ""}`}
        >
          Close
        </button>
      </div>
    </>
  );
};

// Expand year blocks
type SubmenuProps = {
  yearlyLinks: WorksLinkArray[];
  hideSubmenu: VoidFunction;
};

const SubmenuList = (props: SubmenuProps) => {
  const { yearlyLinks, hideSubmenu } = props;

  // NOTE: Omit years
  return (
    <ul>
      {Object.entries(yearlyLinks).map(([key, value]) => (
        <LinkList key={key} links={value.data} hideSubmenu={hideSubmenu} />
      ))}
    </ul>
  );
};

// Expand each year's links
type LinkProps = {
  links: LinkObject[];
  hideSubmenu: VoidFunction;
};

const LinkList = (props: LinkProps) => {
  const { links, hideSubmenu } = props;
  const onKeyHandler = () => null;

  return (
    <>
      {Object.entries(links).map(([key, value]) => (
        <li key={key}>
          <div
            role="button"
            aria-label={value.title}
            tabIndex={0}
            onClick={hideSubmenu}
            onKeyDown={onKeyHandler}
            onKeyUp={onKeyHandler}
          >
            <CustomAnchor
              id={value.id}
              type={value.type}
              title={value.title}
              url={value.url}
              target={value.target}
            />
          </div>
        </li>
      ))}
    </>
  );
};
