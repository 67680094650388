import React, { FC, useEffect } from "react";
import Styles from "../../scss/components/Modal.module.scss";
import { CustomButton } from "../CustomButton";

type ModalProps = {
  state: string;
  message: string;
  setState: React.Dispatch<string>;
};

export const Modal: FC<ModalProps> = ({ state, message, setState }) => {
  // Fadeout
  useEffect(() => {
    if (state === "complete") {
      setTimeout(() => {
        setState("done");
      }, 1000);
    } else if (state === "error") {
      // leave open
    }

    const body = document.querySelector("body");
    if (body) {
      if (state !== "hide") {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "initial";
      }
    }
  }, [setState, state]);

  const resetModal = () => {
    if (state === "done") {
      setState("hide");
    }
  };

  const okClickHandler = () => {
    setState("done");
  };

  return (
    <div
      onTransitionEnd={resetModal}
      className={`${Styles.modal} ${
        state === "show" || state === "complete" || state === "error"
          ? Styles.show
          : ""
      } ${state === "done" ? Styles.default : ""}`}
      tabIndex={-1}
      role="dialog"
    >
      <div className={Styles.modal_dialog} role="document">
        <div className={Styles.modal_body}>
          <div
            className={`${Styles.loader_container} ${
              state === "complete" || state === "done" || state === "error"
                ? Styles.hide
                : ""
            }`}
          >
            <div className={Styles.loader}>loading</div>
          </div>
          <p className={`${state === "error" ? Styles.error : ""}`}>
            {message}
          </p>
          <div
            className={`${Styles.ok} ${state === "error" ? Styles.show : ""}`}
          >
            <CustomButton id="ok" title="OK" click={okClickHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};
