import React, { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

// FIrebase
// https://exerror.com/attempted-import-error-firebase-app-does-not-contain-a-default-export-imported-as-firebase/
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "../../configs/FirebaseConfig";
import initializeApp = firebase.initializeApp;

import { Modal } from "../Modal";
import Styles from "../../scss/views/Info.module.scss";

// Contact Form
export const ContactForm: FC = () => {
  const [hover, setHover] = useState(false);

  // Modal Message
  const [modalState, setModalState] = useState("hide");
  const [modalMessage, setModalMessage] = useState("");

  // Contact Data
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  // After complete
  const clearForm = () => {
    const resetData = {
      name: "",
      email: "",
      message: "",
    };

    setCanSubmit(false);
    setLoading(false);
    setFormData(resetData);
    setErrors(resetData);
  };

  // On server-side error
  const keepForm = () => {
    setCanSubmit(true);
    setLoading(false);
  };

  // Form validation
  const onChangeHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Validation
    if (value === "") {
      // Empty
      setErrors({
        ...errors,
        [name]: "empty",
      });
    } else {
      setErrors({
        ...errors,
        [name]: "ok",
      });
    }
  };

  // Email validation
  const checkEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "email" && value !== "") {
      const regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!regex.test(value)) {
        setErrors({
          ...errors,
          email: "invalid",
        });
      } else {
        setErrors({
          ...errors,
          email: "ok",
        });
      }
    }
  };

  // Adjust textarea
  const autoTextAreaHeight = () => {
    const textArea = document.getElementById("message");
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight.toString()}px`;
    }
  };

  // Submit Form
  const onSubmitHandler = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();

    setModalState("show");
    setModalMessage("Sending your message...");

    // Send mail
    try {
      const app = initializeApp(firebaseConfig);
      getAnalytics(app);

      const sendMail = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("sendMail");
      await sendMail({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });

      setModalMessage("Complete");
      clearForm();
      setModalState("complete");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      if (e instanceof Error) {
        setModalMessage(e.message);
      } else {
        setModalMessage("An unknown error has occurred. Please submit again.");
      }

      keepForm();
      setModalState("error");
    }
  };

  useEffect(() => {
    const errObj = Object.entries(errors);
    const canFlag =
      errObj.filter(([, value]) => value === "ok").length === errObj.length;
    setCanSubmit(canFlag && !loading);
    autoTextAreaHeight();
  }, [canSubmit, errors, loading]);

  // Mouse
  const onMouseHandler = (state: boolean) => {
    if (isMobile) return;
    setHover(state);
  };

  const onTouchHandler = (state: boolean) => {
    if (!isMobile) return;
    setHover(state);
  };

  return (
    <form
      method="post"
      action=""
      className={Styles.contact_form}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmitHandler}
    >
      <dl>
        <div>
          <dt>
            <label htmlFor="name">Name</label>
          </dt>
          <dd>
            <input
              type="text"
              id="name"
              name="name"
              maxLength={255}
              placeholder="Name"
              onBlur={(e) => onChangeHandle(e)}
              onChange={(e) => onChangeHandle(e)}
              value={formData.name}
              disabled={loading}
            />
            {errors.name === "empty" && (
              <p className={Styles.error}>Name is required.</p>
            )}
          </dd>
        </div>
        <div>
          <dt>
            <label htmlFor="email">Email Address</label>
          </dt>
          <dd>
            <input
              type="email"
              id="email"
              name="email"
              maxLength={255}
              placeholder="Email Address"
              onBlur={(e) => {
                onChangeHandle(e);
                checkEmail(e);
              }}
              onChange={(e) => {
                onChangeHandle(e);
                checkEmail(e);
              }}
              value={formData.email}
              disabled={loading}
            />
            {errors.email === "empty" && (
              <p className={Styles.error}>Email address is required.</p>
            )}
            {errors.email === "invalid" && (
              <p className={Styles.error}>Entered email address is invalid.</p>
            )}
          </dd>
        </div>
        <div>
          <dt>
            <label htmlFor="message">Message</label>
          </dt>
          <dd>
            <textarea
              id="message"
              name="message"
              rows={1}
              maxLength={5000}
              placeholder="Message"
              onBlur={(e) => {
                onChangeHandle(e);
                autoTextAreaHeight();
              }}
              onChange={(e) => {
                onChangeHandle(e);
                autoTextAreaHeight();
              }}
              value={formData.message}
              disabled={loading}
            />
            {errors.message === "empty" && (
              <p className={Styles.error}>Message is required.</p>
            )}
          </dd>
        </div>
        <div>
          <dt>
            <label htmlFor="submit">Submit</label>
          </dt>
          <dd>
            <input
              id="submit"
              name="submit"
              type="submit"
              value="Submit"
              onMouseOver={() => onMouseHandler(true)}
              onMouseOut={() => onMouseHandler(false)}
              onFocus={() => onMouseHandler(true)}
              onBlur={() => onMouseHandler(false)}
              onTouchStart={() => onTouchHandler(true)}
              onTouchEnd={() => onTouchHandler(false)}
              onClick={() => {
                onMouseHandler(false);
                onTouchHandler(false);
              }}
              disabled={!canSubmit}
              className={`${Styles.submit} ${hover ? Styles.on : ""}`}
            />
          </dd>
        </div>
      </dl>
      <Modal
        state={modalState}
        message={modalMessage}
        setState={setModalState}
      />
    </form>
  );
};
