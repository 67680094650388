import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import CookieConsent from "react-cookie-consent";

import { RootState } from "../../store";
import { CustomAnchor } from "../CustomAnchor";
import "../../scss/components/CustomCookieConsent.scss";

// Custom Cookie Consent
export const CustomCookieConsent: FC = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const pageType = useSelector((state: RootState) => state.meta.pageType);

  return (
    <CookieConsent
      buttonText="I agree"
      disableStyles
      hideOnAccept={false}
      onAccept={() => setIsAccepted(true)}
      containerClasses={`CookieConsent ${pageType === "work" ? "work" : ""} ${isAccepted ? "accepted" : ""}`}
    >
      This website uses cookies to ensure you get the best experience on our
      website.&nbsp;
      <CustomAnchor
        id="cookie"
        type="link"
        title="Learn more"
        url="https://www.cookiesandyou.com/"
        target="_blank"
      />
    </CookieConsent>
  );
};
