import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
export const ScrollToTop = (): null => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Workaround for iOS
    if (hash === "") {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};
