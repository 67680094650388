import React, { FC, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Styles from "../../scss/components/CustomAnchor.module.scss";

export const CustomAnchor: FC<LinkObject> = (props) => {
  const [hover, setHover] = useState(false);
  const link = props;

  const onMouseHandler = (state: boolean) => {
    if (isMobile) return;
    setHover(state);
  };

  const onTouchHandler = (state: boolean) => {
    if (!isMobile) return;
    setHover(state);
  };

  if (link.type === "link") {
    return (
      <a
        id={link.id}
        href={link.url}
        target={link.target === "" ? undefined : link.target}
        onMouseOver={() => onMouseHandler(true)}
        onMouseOut={() => onMouseHandler(false)}
        onFocus={() => onMouseHandler(true)}
        onBlur={() => onMouseHandler(false)}
        onTouchStart={() => onTouchHandler(true)}
        onTouchEnd={() => onTouchHandler(false)}
        className={`${Styles.link} ${hover ? Styles.on : ""}`}
      >
        {link.title}
      </a>
    );
  }

  if (link.type === "hash") {
    return (
      <HashLink
        smooth
        id={link.id}
        to={link.url}
        onMouseOver={() => onMouseHandler(true)}
        onMouseOut={() => onMouseHandler(false)}
        onFocus={() => onMouseHandler(true)}
        onBlur={() => onMouseHandler(false)}
        onTouchStart={() => onTouchHandler(true)}
        onTouchEnd={() => onTouchHandler(false)}
        className={`${Styles.link} ${hover ? Styles.on : ""}`}
      >
        {link.title}
      </HashLink>
    );
  }

  return (
    <Link
      id={link.id}
      to={link.url}
      target={link.target === "" ? undefined : link.target}
      onMouseOver={() => onMouseHandler(true)}
      onMouseOut={() => onMouseHandler(false)}
      onFocus={() => onMouseHandler(true)}
      onBlur={() => onMouseHandler(false)}
      onTouchStart={() => onTouchHandler(true)}
      onTouchEnd={() => onTouchHandler(false)}
      className={`${Styles.link} ${hover ? Styles.on : ""}`}
    >
      {link.title}
    </Link>
  );
};
