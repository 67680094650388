import React, { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

import Logo from "assets/logo_0bjekt.svg";
import { CustomAnchor } from "../CustomAnchor";
import { WorksSubmenu } from "../WorksSubmenu";
import { useNav } from "../../store/global/atoms/nav";
import Styles from "../../scss/components/Header.module.scss";

// Header
export const Header: FC = () => {
  const { shouldShowNav } = useNav();
  const [hover, setHover] = useState(false);

  // Check path for the h1 tag
  const isHome = useLocation().pathname === "/";

  // On Mouse
  const onMouseHandler = (state: boolean) => {
    if (isMobile) return;
    setHover(state);
  };

  const onTouchHandler = (state: boolean) => {
    if (!isMobile) return;
    setHover(state);
  };

  // Hide the Header on app pages
  if (!shouldShowNav) return null;

  return (
    <header>
      {/* Heading */}
      {isHome ? (
        <h1
          onMouseOver={() => onMouseHandler(true)}
          onMouseOut={() => onMouseHandler(false)}
          onFocus={() => onMouseHandler(true)}
          onBlur={() => onMouseHandler(false)}
          onTouchStart={() => onTouchHandler(true)}
          onTouchEnd={() => onTouchHandler(false)}
          className={`${Styles.logo_wrapper} ${hover ? Styles.on : ""}`}
        >
          <Link to="/" className={Styles.logo}>
            <img src={Logo} alt="0bjekt.co" />
          </Link>
        </h1>
      ) : (
        <h2
          onMouseOver={() => onMouseHandler(true)}
          onMouseOut={() => onMouseHandler(false)}
          onFocus={() => onMouseHandler(true)}
          onBlur={() => onMouseHandler(false)}
          onTouchStart={() => onTouchHandler(true)}
          onTouchEnd={() => onTouchHandler(false)}
          className={`${Styles.logo_wrapper} ${hover ? Styles.on : ""}`}
        >
          <Link to="/" className={Styles.logo}>
            <img src={Logo} alt="0bjekt.co" />
          </Link>
        </h2>
      )}

      <nav>
        <ul>
          <li className={`${isMobile && !isTablet ? Styles.hide : ""}`}>
            <CustomAnchor id="header_home" type="router" title="Home" url="/" />
          </li>
          <li>
            <WorksSubmenu />
          </li>
          <li>
            <CustomAnchor
              id="header_info"
              type="router"
              title="Info"
              url="/info"
            />
          </li>
          <li>
            <CustomAnchor
              id="header_contact"
              type="hash"
              title="Contact"
              url="/info#contact"
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};
