import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { setMeta, clearMeta, MetaState } from "../store/meta";

import { CustomAnchor } from "../components/CustomAnchor";
import Styles from "../scss/views/Home.module.scss";
import WorksData from "../vo/WorksData";

// HOME
export const Home: FC = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    const meta: MetaState = {
      path: location.pathname,
      type: "website",
      pageType: "home",
      title: "",
      keywords: "",
      description: "",
    };

    dispatch(setMeta(meta));
    return () => {
      dispatch(clearMeta());
    };
  }, [props, dispatch, location]);

  return (
    <article className={Styles.home}>
      <WorksList yearlyLinks={WorksData} />
    </article>
  );
};

// Expand year blocks
type WorksProps = {
  yearlyLinks: WorksLinkArray[];
};

const WorksList: FC<WorksProps> = ({ yearlyLinks }) => (
  <>
    {Object.entries(yearlyLinks).map(([key, value]) => (
      <div key={key}>
        <dl>
          <dt>{value.year}</dt>
          <dd>
            <LinkList links={value.data} />
          </dd>
        </dl>
      </div>
    ))}
  </>
);

// Expand each year's links
type LinkProps = {
  links: WorksObject[];
};

const LinkList = (props: LinkProps) => {
  const { links } = props;

  return (
    <ul>
      {Object.entries(links).map(([key, value]) => (
        <li key={key}>
          <WorkAnchor work={value} />
        </li>
      ))}
    </ul>
  );
};

// Wrapped Custom Anchor
type WorkProps = {
  work: WorksObject;
};

const WorkAnchor = (props: WorkProps) => {
  const { work } = props;
  const [hover, setHover] = useState(false);

  const onMouseHandler = (state: boolean) => {
    if (isMobile) return;
    setHover(state);
  };

  const onTouchHandler = (state: boolean) => {
    if (!isMobile) return;
    setHover(state);
  };

  return (
    <>
      <div
        onMouseOver={() => onMouseHandler(true)}
        onMouseOut={() => onMouseHandler(false)}
        onFocus={() => onMouseHandler(true)}
        onBlur={() => onMouseHandler(false)}
        onTouchStart={() => onTouchHandler(true)}
        onTouchEnd={() => onTouchHandler(false)}
        className={`${Styles.anchor_wrapper} ${hover ? Styles.on : ""}`}
      >
        <CustomAnchor
          id={work.id}
          type={work.type}
          title={work.title}
          url={work.url}
          target={work.target}
        />
        <div className={Styles.underline} />
      </div>
      <div className={Styles.icons}>
        {work.hasSound ? (
          <i className={Styles.sound} title="This work has sound">
            hasSound
          </i>
        ) : (
          ""
        )}
        {work.flashes ? (
          <i className={Styles.flash} title="The screen will flashes heavily">
            flashes
          </i>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
