import React, { FC, useState } from "react";
import { isMobile } from "react-device-detect";
import Styles from "../../scss/components/CustomAnchor.module.scss";

// button as link
export const CustomButton: FC<ButtonObject> = (props) => {
  const button = { ...props };
  const [hover, setHover] = useState(false);

  const onMouseHandler = (state: boolean) => {
    if (isMobile) return;
    setHover(state);
  };

  const onTouchHandler = (state: boolean) => {
    if (!isMobile) return;
    setHover(state);
  };

  const onClickHandler = (btn: ButtonObject) => {
    // setHover(false);
    btn.click(btn);
  };

  return (
    <button
      type="button"
      id={button.id}
      onMouseOver={() => onMouseHandler(true)}
      onMouseOut={() => onMouseHandler(false)}
      onFocus={() => onMouseHandler(true)}
      onBlur={() => onMouseHandler(false)}
      onTouchStart={() => onTouchHandler(true)}
      onTouchEnd={() => onTouchHandler(false)}
      onClick={() => onClickHandler(button)}
      className={`${Styles.link} ${hover ? Styles.on : ""}`}
    >
      {button.title}
    </button>
  );
};
