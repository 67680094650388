import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMeta, clearMeta, MetaState } from "../store/meta";
import { setWork, clearWork } from "../store/works";

import { CustomAnchor } from "../components/CustomAnchor";
import Styles from "../scss/views/Prelude2014.module.scss";

// Images
import prelude001 from "../assets/prelude_001.jpg";
import prelude002 from "../assets/prelude_002.jpg";
import prelude003 from "../assets/prelude_003.png";

// Prelude - 2014
export const Prelude2014: FC = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const meta: MetaState = {
      path: location.pathname,
      type: "article",
      pageType: "work",
      title: "Prelude Reconstruction | 2014",
      keywords: "Prelude Reconstruction",
      description: "A sound software and installation project in 2014",
    };

    dispatch(setMeta(meta));
    dispatch(setWork(true));

    return () => {
      dispatch(clearMeta());
      dispatch(clearWork());
    };
  }, [props, dispatch, location]);

  return (
    <article className={`${Styles.work} prelude`}>
      <div className={Styles.image_1}>
        <img src={prelude001} alt="Installation View" />
      </div>
      <div className={Styles.image_2}>
        <img src={prelude002} alt="Installation View" />
      </div>
      <div className={Styles.image_3}>
        <img src={prelude003} alt="Application Capture" />
      </div>
      <div className={Styles.info}>
        <h1 className="work">Prelude Reconstruction</h1>
        <ul>
          <li>2014</li>
          <li>
            Sound installation (Smartphone, software, headphone, drawing on the
            wall)
          </li>
          <li>Dimensions variable</li>
          <li>
            <CustomAnchor
              id="prelude_2014"
              type="link"
              title="Exhibition at CON/PER/CEPTUAL #3, Art Lab AKIBA, Tokyo"
              url="https://www.tokyoartbeat.com/event/2014/0B92"
              target="_blank"
            />
          </li>
        </ul>
        <p>
          In this work, the sound source of Sergei Rachmaninoff&#39;s &quot;13
          Preludes, Op. 32, No. 10 in B minor&quot; (performed by La Pianista)
          is randomly extracted and played back from a specified starting time,
          with a minimum length of 44 milliseconds and a maximum length of 2.2
          seconds, and the process is repeated indefinitely. What you hear
          through the headphones is a collection of fragments of the performance
          and glitch noise caused by the sound source&#39;s chopping up.
        </p>
        <p>
          The software was built with Processing and installed as an application
          on an Android smartphone. It displays the starting position and the
          duration of each sound piece visually.
        </p>
        <p>
          Furthermore, on this installation, on the wall to the smartphone&#39;s
          right, the application code is written in pencil roughly. It attempts
          to visualize and include the trial and error and working process in
          this exhibition.
        </p>
      </div>
    </article>
  );
};
