// https://console.firebase.google.com/project/objekt-co/settings/general/web:M2UzMjFjNWUtYjA0YS00ZjlmLTkxY2ItZDVjODE4YzE0OWVl
export const firebaseConfig = {
  apiKey: "AIzaSyBlsrk283QG38HmS3vAADg3ufRvCA1_1-Q",
  authDomain: "objekt-co.firebaseapp.com",
  projectId: "objekt-co",
  storageBucket: "objekt-co.appspot.com",
  messagingSenderId: "1035653230995",
  appId: "1:1035653230995:web:5a6fde29970d28c45d7866",
  measurementId: "G-XZ8JV8PBC9",
};
