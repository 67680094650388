import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { firebaseConfig } from "../configs/FirebaseConfig";
import initializeApp = firebase.initializeApp;
import { clearMeta, MetaState, setMeta } from "../store/meta";
import { ReturnToHome } from "../components/ReturnToHome";
import { useNav } from "../store/global/atoms/nav";
import { useSound } from "../components/LatestNewsToday2024/useSound";
import Styles from "../scss/views/LatestNewsToday2024.module.scss";

type News = {
  author: string | null;
  content: string | null;
  description: string | null;
  publishedAt: string | null;
  source: {
    id: string | null;
    name: string | null;
  };
  title: string | null;
  url: string | null;
  urlToImage: string | null;
};

type Response = {
  data: {
    response: "ok" | "error";
    res: {
      status: string;
      totalResults: number;
      articles: News[];
    };
  };
};

// prettier-ignore
const textMap = [
  "a", "b", "c", "d", "e",
  "f", "g", "h", "i", "j",
  "k", "l", "m", "n", "o",
  "p", "q", "r", "s", "t",
  "u", "v", "w", "x", "y",
  "z",
  "A", "B", "C", "D", "E",
  "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O",
  "P", "Q", "R", "S", "T",
  "U", "V", "W", "X", "Y",
  "Z",
  "0", "1", "2", "3", "4",
  "5", "6", "7", "8", "9",
  "/", "*", "-", "+", ".",
  ",", ":", ";", "_", "=",
  "~", "^", "|", "{", "}",
  "[", "]", "(", ")", "<",
  ">", "!", "?", "@", "#",
  "$", "£", "€", "%", "&",
  "'", "\"", "\\", " ", "",
];

export const LatestNewsToday2024: FC = () => {
  // Meta
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const meta: MetaState = {
      path: location.pathname,
      type: "article",
      pageType: "content",
      title: "Latest News Today | 2024",
      keywords: "",
      description: "The Latest News Today | 2024",
    };

    dispatch(setMeta(meta));
    return () => {
      dispatch(clearMeta());
    };
  }, [dispatch, location]);

  // Content
  const { setShouldShowNav } = useNav();
  const { playBassBeep } = useSound();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const frameRef = useRef<number>(0);
  const lastTimeRef = useRef<number>(Date.now());

  const waitRef = useRef<number>(0);
  const requestRef = useRef<number>();

  const [news, setNews] = useState<News[]>([]);
  const [newsIndex, setNewsIndex] = useState(0);
  const article = useMemo(() => news[newsIndex], [news, newsIndex]);

  const modifyString = useCallback((str: string | null) => {
    if (!str) return "";

    const strArr = str.split("");

    for (let i = 0; i < strArr.length; i++) {
      const rnd = Math.floor(Math.random() * 100);

      if (rnd === 1) {
        strArr[i] = textMap[Math.floor(Math.random() * textMap.length)];
      } else if (rnd === 2) {
        strArr[i] += textMap[Math.floor(Math.random() * textMap.length)];
      } else if (rnd === 3 || rnd === 4) {
        strArr[i] = strArr[i].toUpperCase();
      } else if (rnd === 5 || rnd === 6) {
        strArr[i] = strArr[i].toLowerCase();
      } else if (strArr[i] === " ") {
        strArr[i] = Math.floor(Math.random() * 10) === 1 ? "" : strArr[i];
      }
    }

    return strArr.join("");
  }, []);

  const animate = useCallback(() => {
    let fps = 60;
    const time = Date.now();
    if (frameRef.current > 0 && time - lastTimeRef.current >= 1000) {
      fps = (frameRef.current * 1000) / (time - lastTimeRef.current);
      lastTimeRef.current = time;
      frameRef.current = 0;
    }

    if (waitRef.current <= 0) {
      setNewsIndex(Math.floor(Math.random() * 100));

      const duration = Math.floor(Math.random() * 10)
        ? Math.random() * 4 + 1
        : Math.random() * 200 + 1;

      playBassBeep(duration, fps);
      waitRef.current = duration;
    } else {
      waitRef.current -= 1;
    }

    frameRef.current++;
    requestRef.current = requestAnimationFrame(animate);
  }, [playBassBeep]);

  const getArticles = useCallback(async () => {
    try {
      initializeApp(firebaseConfig);

      const getNews = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("getNews");

      const articles = (await getNews()) as Response;
      setNews(articles.data.res.articles);
      animate();
    } catch (e: unknown) {
      console.log(e);
      setIsError(true);
    } finally {
      setIsLoaded(true);
    }
  }, [animate]);

  useEffect(() => {
    setShouldShowNav(false);
    getArticles();

    return () => {
      setShouldShowNav(true);

      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoaded) {
    return (
      <>
        <ReturnToHome theme="light" />
        <div className={`${Styles.loader_container}`}>
          <div className={Styles.loader}>loading</div>
        </div>
      </>
    );
  }

  return (
    <>
      <ReturnToHome theme="light" />
      {isError || news.length === 0 ? (
        <div className={Styles.error}>API Error</div>
      ) : (
        <div className={Styles.content}>
          <div>{modifyString(article.title)}</div>
          <div className={Styles.break}>
            {modifyString(article.source.name)} | {modifyString(article.url)}
          </div>
          <div>
            {modifyString(article.publishedAt)}
            {article.author ? ` | ${modifyString(article.author)}` : ""}
          </div>
          <div>{modifyString(article.description)}</div>
          <div>{modifyString(article.content)}</div>
        </div>
      )}
    </>
  );
};
