import { combineReducers, configureStore } from "@reduxjs/toolkit";
import metaReducer from "./meta";
import worksReducer from "./works";

const reducer = combineReducers({
  meta: metaReducer,
  works: worksReducer,
});

const store = configureStore({ reducer });

export type RootState = ReturnType<typeof store.getState>;
export default store;
