import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// https://www.hypertextcandy.com/learn-react-redux-with-hooks-and-redux-starter-kit
const initialState = {
  isWork: false,
};

const worksSlice = createSlice({
  name: "works",
  initialState,
  reducers: {
    setWork: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isWork: action.payload,
    }),
    clearWork: (state) => ({ ...state, isWork: false }),
  },
});

export default worksSlice.reducer;
export const { setWork, clearWork } = worksSlice.actions;
