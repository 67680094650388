import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MetaState {
  path: string;
  type: string;
  pageType: string;
  title: string;
  keywords: string;
  description: string;
}

// https://www.hypertextcandy.com/learn-react-redux-with-hooks-and-redux-starter-kit
const initialState: MetaState = {
  path: "",
  type: "website",
  pageType: "",
  title: "",
  keywords: "",
  description: "",
};

const baseTitle = "0bjekt.co";

const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setMeta: (state, action: PayloadAction<MetaState>) => {
      let { title } = action.payload;
      let ogTitle = title;

      if (title === "") {
        title = baseTitle;
        ogTitle = baseTitle;
      } else {
        title += ` | ${baseTitle}`;
      }

      let ogType = action.payload.type;
      if (ogType === "website") {
        ogType = "website";
      } else {
        ogType = "article";
      }

      const setState: MetaState = {
        path: action.payload.path,
        type: ogType,
        pageType: action.payload.pageType,
        title,
        keywords: action.payload.keywords,
        description: action.payload.description,
      };

      // Meta
      document.title = title;

      const metas = document.head.children;
      for (let i = 0; i < metas.length; i += 1) {
        const property = metas[i].getAttribute("property");
        const name = metas[i].getAttribute("name");

        if (property === "og:title") {
          metas[i].setAttribute("content", ogTitle);
        } else if (name === "keywords") {
          metas[i].setAttribute("content", setState.keywords);
        } else if (name === "description" || property === "og:description") {
          metas[i].setAttribute("content", setState.description);
        } else if (property === "og:type") {
          metas[i].setAttribute("content", setState.type);
        } else if (property === "og:url") {
          metas[i].setAttribute("content", `https://0bjekt.co${setState.path}`);
        }
      }

      return { ...state, ...setState };
    },
    clearMeta: (state) => {
      const resetState: MetaState = {
        path: "",
        type: "",
        pageType: "",
        title: "",
        keywords: "",
        description: "",
      };

      document.title = baseTitle;
      return { ...state, ...resetState };
    },
  },
});

export default metaSlice.reducer;
export const { setMeta, clearMeta } = metaSlice.actions;
