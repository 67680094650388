import React, { FC } from "react";
import { CustomAnchor } from "../CustomAnchor";
import { useNav } from "../../store/global/atoms/nav";
import Styles from "../../scss/components/Footer.module.scss";

// Footer
const links: LinkObject[] = [
  {
    id: "instagram",
    type: "link",
    title: "Instagram",
    url: "https://www.instagram.com/ts5h_/",
    target: "_blank",
  },
  {
    id: "facebook",
    type: "link",
    title: "Facebook",
    url: "https://facebook.com/at5ush1",
    target: "_blank",
  },
];

export const Footer: FC = () => {
  const { shouldShowNav } = useNav();

  // Hide the Footer on app pages
  if (!shouldShowNav) return null;

  return (
    <footer>
      <p className={Styles.copyright}>&copy;2018 0bjekt / ts5h</p>
      <div className={Styles.link}>
        <ul>
          {/* Footer Links */}
          {Object.entries(links).map(([key, value]) => (
            <li key={key}>
              <CustomAnchor
                id={value.id}
                type={value.type}
                title={value.title}
                url={value.url}
                target={value.target}
              />
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};
