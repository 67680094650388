import React, { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import { BodyClasses } from "./components/BodyClasses";
import { ScrollToTop } from "./components/ScrollToTop";
import { CustomCookieConsent } from "./components/CustomCookieConsent";
import { FirebaseAnalytics } from "./components/FirebaseAnalytics";

// Common
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import "./scss/App.scss";

// Pages
import { Home } from "./views/Home";
import { LatestNewsToday2024 } from "./views/LatestNewsToday2024";
import { Prelude2014 } from "./views/Prelude2014";
import { Untitled2009 } from "./views/Untitled2009";
import { Info } from "./views/Info";
import { NotFound } from "./views/NotFound";

const App: FC = () => (
  <BrowserRouter>
    <BodyClasses />
    <ScrollToTop />
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/2024/latest_news_today"
          element={<LatestNewsToday2024 />}
        />
        <Route path="/2014/prelude" element={<Prelude2014 />} />
        <Route path="/2009/untitled" element={<Untitled2009 />} />
        <Route path="/info" element={<Info />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
    <CustomCookieConsent />
    <FirebaseAnalytics />
  </BrowserRouter>
);

export default App;
