import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMeta, clearMeta, MetaState } from "../store/meta";

import { ContactForm } from "../components/ContactForm";
import { CustomAnchor } from "../components/CustomAnchor";
import Styles from "../scss/views/Info.module.scss";

// Info & Contact
export const Info: FC = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const meta: MetaState = {
      path: location.pathname,
      type: "article",
      pageType: "info",
      title: "Info",
      keywords: "",
      description: "Artist information and contact",
    };

    dispatch(setMeta(meta));
    return () => {
      dispatch(clearMeta());
    };
  }, [props, dispatch, location]);

  return (
    <article className={Styles.info}>
      <h1>Information</h1>
      <p>
        <strong>0bjekt</strong> is an art project of Atsushi Yamazaki (ts5h).
        <br />
        He has been interested in the fusion of technology and art and has been
        working using various technologies (mainly web and some digital
        technologies).
        <br />
        Born in Hokkaido, Japan, currently working in Amsterdam and Tokyo.
      </p>

      {/* Exhibitions */}
      <section className={Styles.exhibitions}>
        <h2>Exhibitions</h2>
        <dl>
          <div>
            <dt>2014</dt>
            <dd>
              <CustomAnchor
                id="exhibitions-1"
                type="link"
                title="CON/PER/CEPTUAL #3, group exhibition, Art Lab AKIBA, Tokyo"
                url="https://www.tokyoartbeat.com/event/2014/0B92"
                target="_blank"
              />
            </dd>
          </div>
        </dl>
      </section>

      {/* Performances */}
      <section className={Styles.performances}>
        <h2>Performances</h2>
        <dl>
          <div>
            <dt>2014</dt>
            <dd>
              <CustomAnchor
                id="2014-1"
                type="link"
                title='"CON/PER/CEPTUAL" Special Live!, video processing performance / live session, Art Lab AKIBA, Tokyo, March 01'
                url="https://artlab-tokyo.com/exhibition/past_a.html"
                target="_blank"
              />
            </dd>
          </div>
          <div>
            <dt>2011</dt>
            <dd>
              <CustomAnchor
                id="2011-1"
                type="link"
                title='Occasional Speech: 9.11 Report on the 54th Venice Biennale at Shin Minatomura — "The ART in Japan and East Asia as seen from Venice", sound installation, Shin Minatomura, Yokohama, September 11'
                url="http://shinminatomura.com/"
                target="_blank"
              />
            </dd>
          </div>
        </dl>
      </section>

      {/* Contact */}
      <section className={Styles.contact}>
        <h2 id="contact">Contact</h2>
        <ContactForm />
      </section>
    </article>
  );
};
