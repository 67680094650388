import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "../../configs/FirebaseConfig";

// Google Analytics
// https://stackoverflow.com/questions/61120656/sending-react-router-page-views-to-firebase-analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const FirebaseAnalytics: FC = () => {
  const { pathname, hash, search } = useLocation();

  useEffect(() => {
    const pagePath = `${pathname}${hash}${search}`;
    logEvent(analytics, "page_view", {
      page_path: pagePath,
    });
  }, [pathname, hash, search]);

  return null;
};
