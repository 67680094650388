import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMeta, clearMeta } from "../store/meta";
import { setWork, clearWork } from "../store/works";
import Styles from "../scss/views/Untitled2009.module.scss";

// Images
import UntitledImg from "../assets/untitled_2009.png";

// Untitled - 2009
export const Untitled2009: FC = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const meta = {
      path: location.pathname,
      type: "article",
      pageType: "work",
      title: "Untitled | 2009",
      keywords: "Untitled",
      description: "An untitled digital graphic work in 2009",
    };

    dispatch(setMeta(meta));
    dispatch(setWork(true));

    return (): void => {
      dispatch(clearMeta());
      dispatch(clearWork());
    };
  }, [props, dispatch, location]);

  return (
    <article className={`${Styles.work} untitled`}>
      <div className={`image_wrapper ${Styles.image_wrapper}`}>
        <img className={Styles.image} src={UntitledImg} alt="Untitled | 2009" />
      </div>
      <div className={Styles.info}>
        <h1>Untitled</h1>
        <ul>
          <li>2009</li>
          <li>CG</li>
          <li>18.8x26.3cm</li>
        </ul>
      </div>
    </article>
  );
};
